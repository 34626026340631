export default {
  COMMON: "common",
  CELEBRITY: "celebrity",
  CELEBRITY_2: "celebrity2",
  GLOSS: "gloss",
  PRO: "pro",
  RETOUCH: "retouch",
  TAB3: "tab3",
  FULL_SIZE: "full_size",
  MIDJOURNEY: "midjourney",
};
