import React from "react";
import Slider from "react-slick";
import {assetUrl} from "../../utils/etc";

const slides = [
  {img: assetUrl("assets/images/slider/1.jpg")},
  {img: assetUrl("assets/images/slider/2.jpg")},
  {img: assetUrl("assets/images/slider/3.jpg")},
  {img: assetUrl("assets/images/slider/4.jpg")},
  {img: assetUrl("assets/images/slider/5.jpg")},
  {img: assetUrl("assets/images/slider/6.jpg")},
  {img: assetUrl("assets/images/slider/7.jpg")},
  {img: assetUrl("assets/images/slider/8.jpg")},
];

const settings = {
  dots: true,
  arrows: false,
  className: "swiper",
  infinite: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 1000,
  centerMode: true,
  centerPadding: "220px",
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "280px"
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "360px"
      }
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "380px",
        dots: false,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "340px",
        dots: false,
      }
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "240px",
        dots: false,
      }
    },
    {
      breakpoint: 730,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "240px",
        dots: false,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "200px",
        dots: false,
      }
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "160px",
        dots: false,
      }
    },
    {
      breakpoint: 460,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "100px",
        dots: false,
      }
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "55px",
        dots: false,
      }
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "80px",
        dots: false,
      }
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "100px",
        dots: false,
      }
    }
  ]
};

export default class SliderView extends React.Component {
  render() {
    return <div className="slider">
      <Slider {...settings}>
        {slides.map((slide) =>
          <div className="slide" key={slide.img}>
            <img
              className="image full"
              src={slide.img}
              alt=""/>
          </div>
        )}
      </Slider>
    </div>;
  }
}