import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./routes";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import TestInstanceToast from "./components/TestInstanceToast";
import WindowSizeWatcher from "./components/WindowSizeWatcher";
import IndexPage from "./pages/index/IndexPage";
import CreatePage from "./pages/create/CreatePage";
import PackPage from "./pages/pack/PackPage";
import PacksPage from "./pages/packs/PacksPage";
import neuroApiHelper from "./helpers/neuro-api.helper";
import {generatePath, withRouter} from "react-router";
import {webviewAppIds} from "./utils/webview";

window.onerror = function(message, file, line, col, error) {
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

class App extends React.Component {

  state = {
    isReady: false,
  };

  componentDidMount() {
    if (window.clientConfig.isWebview) {
      neuroApiHelper.setUserClientData(window.clientConfig.webviewParams);
    } else {
      const [,,token] = window.clientConfig.token.split(":");
      const data = {
        app_id: window.clientConfig.query["app_id"] || webviewAppIds.photolabFree,
        aid: window.clientConfig.query["packtoken"] || ("z" + token).slice(0, 32),
      };

      if (window.clientConfig.query["is_mock"] === "1") {
        data.is_mock = 1;
      }

      neuroApiHelper.setUserClientData(data);
    }

    // window.webviewEventsListeners.getUserIds.subscribe((data) => {
    //   neuroApiHelper.setUserCredentials(data);
    neuroApiHelper.setUserCredentials({});
    neuroApiHelper.fetchPacks().then((res) => {
      const latestPack = res.packs
        .sort((a,b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        .filter((p) => p.status === neuroApiHelper.packStatus.ready)
        .first();

      if (window.clientConfig.isWebview && latestPack) {
        webviewUtils.webviewCancelCheck(latestPack.id);
      }

      this.context.setPacks(res.packs);
      this.context.setActiveIds(res.active_ids);

      // if (res.packs.isNotEmpty()) {
      //   const pack = res.packs.first();
      //   if (pack.status === neuroApiHelper.packStatus.pending) {
      //     this.props.history.replace(generatePath(routes.PACK, {id: pack.id}));
      //   }
      // }

      this.setState({isReady: true});
    });
    // });

    if (window.clientConfig.isWebview) {
    //   webviewUtils.webviewGetUserIds();
      webviewUtils.webviewOnPageFinished();
    // } else {
    //   window.webviewEventsListeners.getUserIds.postValue(neuroApiMockUserCredentials);
    }

    window.clientConfig.platform.os.toLowerCase() === "ios" && document.body.classList.add("ios");
    document.body.classList.add("webview");
    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  render() {
    return (
      <div>
        <WindowSizeWatcher />
        <TestInstanceToast />
        <ErrorBoundary>
          <Loading {...this.context.loader} />
          {this.state.isReady && <Switch>
            <Route exact path={routes.INDEX} render={(props) => <IndexPage {...props} />} />
            <Route exact path={routes.CREATE} render={(props) => <CreatePage {...props} />} />
            <Route exact path={routes.PACK} render={(props) => <PackPage {...props} />} />
            <Route exact path={routes.PACKS} render={(props) => <PacksPage {...props} />} />
          </Switch>}
          <ModalWrapper />
        </ErrorBoundary>
      </div>
    );
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppContextProvider>
    <BrowserRouter>
      <AppWithRouter />
    </BrowserRouter>
  </AppContextProvider>
);