import React from "react";
import AppContext from "../../contexts/AppContext";
import {generatePath} from "react-router";
import routes from "../../routes";
import i18n from "../../i18n";
import PackItemView from "./PackItemView";

export default class PacksPage extends React.Component {

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.replace(routes.INDEX);
      return true;
    });
  }

  componentWillUnmount() {
    window.webviewEventsListeners.backPress.pop();
  }

  handleGoToIndexPage = () => {
    this.props.history.push(routes.INDEX);
  }

  handleGoToPackPage = (pack) => {
    this.props.history.push(generatePath(routes.PACK, {id: pack.id}));
  }

  render() {
    const packs = this.context.packs
      .sort((a,b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    return <div className="container">
      <header>
        <button onClick={this.handleGoToIndexPage}>
          <svg viewBox="0 0 24 24"><path d="m5.7 10.5 8.4-8.4L12 0 0 12l12 12 2.1-2.1-8.4-8.4H24v-3z" fill="#fff" fillRule="evenodd"/></svg>
        </button>
        <h1>{i18n.t("packs_page__title")}</h1>
      </header>

      {packs.map((pack) => <PackItemView
        key={pack.id}
        pack={pack}
        onGoToClick={this.handleGoToPackPage}
      />)}
    </div>;
  }
}

PacksPage.contextType = AppContext;
