import Modal from "../../../components/Modal";
import React from "react";
import ImageWatermarkView from "./ImageWatermarkView";
import WatermarkRemovalModal from "./WatermarkRemovalModal";
import i18n from "../../../i18n";
import {tempImagesUploadFile} from "../../../utils/api";
import {promisifyImage} from "../../../utils/image";
import {transformToDownloadUrl} from "../../../utils/creative";
import PropTypes from "prop-types";
import {assetUrl} from "../../../utils/etc";
import {isWebviewApp} from "../../../utils/config.utils";
import {webviewAppIds} from "../../../utils/webview";

const watermarks = {
  newprofilepic: {
    url: assetUrl("assets/watermarks/newprofilepic.png"),
    size: 0.4,
  },
  tooncoin: {
    url: assetUrl("assets/watermarks/tooncoin.png"),
    size: 0.2,
  },
  toonme: {
    url: assetUrl("assets/watermarks/toonme.png"),
    size: 0.3,
  },
  photolab: {
    url: assetUrl("assets/watermarks/photolab.png"),
    size: 0.3,
  },
};

export default class WatermarkBuilderModal extends Modal {

  state = {
    isLoading: true,
    watermarkIsRemoved: false,
    watermarkIsCaptured: false,
  };

  layoutedMedia = null;
  watermarkData = {x: 0, y: 0};

  constructor(props) {
    super(props);

    if (isWebviewApp(webviewAppIds.tooncoin)) {
      this.watermark = watermarks.tooncoin;
    } else if (isWebviewApp(webviewAppIds.toonme, webviewAppIds.toonmeHuawei)) {
      this.watermark = watermarks.toonme;
    } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
      this.watermark = watermarks.newprofilepic;
    } else {
      this.watermark = watermarks.photolab;
    }
  }

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      if (!this.state.isLoading) {
        this.dismiss();
      }

      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handleImageWatermarkViewChanged = (data) => {
    this.layoutedMedia = null;
    this.watermarkData = data;
  };

  handleWatermarkRemoveIntent = () => {
    this.context.pushModal(<WatermarkRemovalModal
      key="WatermarkBuilderModal_WatermarkRemovalModal"
      onConfirm={this.handleWatermarkRemoveConfirm}
      onCancel={this.handleWatermarkRemoveCancel}
    />);
  };

  handleWatermarkRemoveConfirm = () => {
    this.setState({
      watermarkIsRemoved: true,
    }, () => {
      this.layoutedMedia = {
        url: this.props.imageUrl,
        data: null,
      };
    });
  };

  handleWatermarkRemoveCancel = () => {
    // nothing :)
  };

  handleError = (err) => {
    console.error(err);
  };

  handleSaveButtonClick = () => {
    if (this.state.watermarkIsCaptured) {
      return;
    }

    if (this.layoutedMedia) {
      this.save(this.layoutedMedia);
    } else {
      this.setState({
        isLoading: true,
        loadingText: i18n.t("result_page__preparing_photo")
      }, () => {
        this.layoutImage().then((mediaData) => {
          this.layoutedMedia = mediaData;
          this.save(mediaData);
        }).catch((err) => {
          this.handleError(err);
        });
      })
    }
  };

  layoutImage = () => new Promise((resolve, reject) => {
    const proxiedImageUrl = new URL(transformToDownloadUrl(this.props.imageUrl));
    proxiedImageUrl.searchParams.append("rc", "" + Date.now());

    Promise.all([
      promisifyImage(proxiedImageUrl.toString(), true),
      promisifyImage(this.watermark.url, true),
    ]).then(([sourceImage, watermarkImage]) => {
      const canvas = document.createElement("canvas");
      canvas.width = sourceImage.width;
      canvas.height = sourceImage.height;

      const canvasCtx = canvas.getContext("2d");
      canvasCtx.drawImage(sourceImage, 0, 0);
      canvasCtx.drawImage(
        watermarkImage,
        this.watermarkData.x,
        this.watermarkData.y,
        this.watermarkData.width,
        Math.round(watermarkImage.height * (this.watermarkData.width / watermarkImage.width))
      );

      return canvas;
    }).then((canvas) => {
      const extension = this.props.imageUrl
        .split(".")
        .pop()
        .toLowerCase()
        .replace(/jpg/, "jpeg");

      return new Promise((resolve) => canvas.toBlob(resolve, "image/" + extension));
    }).then((blob) => {
      return tempImagesUploadFile(blob, "jpeg");
    }).then((resultUrl) => {
      resolve({url: resultUrl, data: null});
    }).catch(reject);
  });

  save = (media) => {
    this.props.onDownload(media.url, !this.state.watermarkIsRemoved);
    this.dismiss();
  };

  // todo loading state
  render() {
    const saveButtonClasses = ["btn btn-violet save-button"];
    if (this.state.watermarkIsCaptured) {
      saveButtonClasses.push("pretend-delete");
    }

    return <div className="watermark-builder-modal">
      <div className="container">
        <header>
          <button onClick={() => this.dismiss()}>
            <svg viewBox="0 0 24 24">
              <path d="m5.7 10.5 8.4-8.4L12 0 0 12l12 12 2.1-2.1-8.4-8.4H24v-3z" fill="#fff" fillRule="evenodd"/>
            </svg>
          </button>
          <h1>{i18n.t("watermark_screen__title")}</h1>
        </header>
      </div>

      <ImageWatermarkView
        isVideo={false}
        isMuted={false}
        imageUrl={this.props.imageUrl}
        watermarkIsShown={!this.state.watermarkIsRemoved}
        watermarkUrl={this.watermark.url}
        watermarkSize={this.watermark.size}
        onLoaded={() => this.setState({isLoading: false})}
        onError={this.handleError}
        onChange={this.handleImageWatermarkViewChanged}
        onRemoveIntent={this.handleWatermarkRemoveIntent}
        onCaptureStart={() => this.setState({watermarkIsCaptured: true})}
        onCaptureEnd={() => this.setState({watermarkIsCaptured: false})}
      />

      <div className="btns-container">
        <button
          className={saveButtonClasses.join(" ")}
          onClick={this.handleSaveButtonClick}>
          <span dangerouslySetInnerHTML={{__html: i18n.t("download")}} />
          <SvgTrashboxIcon />
        </button>
      </div>

      <div className="loader-watermark" hidden={!this.state.isLoading}>
        <div className="pending-loader-container">
            <div className="pending-loader">
              <div className="pending-loader-slider" />
            </div>
          </div>
      </div>
    </div>
  }
}

WatermarkBuilderModal.propType = {
  onDownload: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

function SvgTrashboxIcon() {
  return <svg viewBox="0 0 52 60">
    <path d="M38.01 60H13.99c-2.599 0-4.782-1.998-5.07-4.64L4.325 13.042h43.35L43.08 55.36C42.792 58.002 40.609 60 38.01 60zM31.1 0c1.408 0 2.55 1.169 2.55 2.609h15.3c1.353 0 2.462 1.08 2.545 2.443l.005.165c0 1.44-1.142 2.61-2.55 2.61H3.05C1.697 7.826.588 6.745.505 5.381L.5 5.217c0-1.44 1.142-2.608 2.55-2.608h15.3C18.35 1.169 19.492 0 20.9 0h10.2z" fillRule="nonzero" />
  </svg>;
}