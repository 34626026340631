import React from "react";
import i18n from "../../i18n";
import CountdownTimer from "../shared/CountdownTimer";
import clientStorage from "../../utils/client-storage";
import SliderView from "./SliderView";
import {assetUrl} from "../../utils/etc";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAppIds} from "../../utils/webview";
import {defaultSlides, newprofilepicSlides, toonmeSlides} from "./shared";

export default class ProcessingView extends React.Component {

  state = {
    timerExpired: false,
    slides: [],
  };

  constructor(props) {
    super(props);

    if (isWebviewApp(webviewAppIds.toonme)) {
      this.state.slides = toonmeSlides.slice();
    } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
      this.state.slides = newprofilepicSlides.slice();
    } else {
      this.state.slides = defaultSlides.slice();
    }
  }

  handleTimerExpired = () => {
    this.setState({timerExpired: true});
  };

  getTimerDateMillis = () => {
    let timerDateMillis = clientStorage.getPackProgressCountdown(this.props.pack.id);

    if (!timerDateMillis) {
      let period = Math.floor((this.props.pack.progress.countdown || 0) * 1000);
      if (period > 360_000) {
        period -= 360_000; // ¯\_(ツ)_/¯
      }

      timerDateMillis = new Date(this.props.pack.created_at).getTime() + period;

      clientStorage.setPackProgressCountdown(this.props.pack.id, timerDateMillis);
    }

    return timerDateMillis;
  }

  renderTimer() {
    if (!this.props.pack) {
      return <p className="notice-text">&nbsp;</p>;
    }

    const timerDateMillis = this.getTimerDateMillis();
    const timerIsVisible = timerDateMillis > Date.now() && !this.state.timerExpired;
    const timerIsExpired = timerDateMillis <= Date.now() || this.state.timerExpired;

    return <React.Fragment>
      {timerIsVisible && <CountdownTimer
        targetMillis={timerDateMillis}
        onExpire={this.handleTimerExpired}
      />}
      <p
        className="notice-text"
        dangerouslySetInnerHTML={{__html: i18n.t("processing_expired_text")}}
        hidden={!timerIsExpired}
      />
    </React.Fragment>
  }

  handleGoToAnotherStaffClick = () => {
    this.props.onGoToAnotherStaffClick && this.props.onGoToAnotherStaffClick();
  };

  render() {
    return <main className="loader">
      <div className="container">
        <header>
          <button onClick={this.props.onBackClick} hidden={!this.props.canBack}>
            <svg viewBox="0 0 24 24">
              <path d="m5.7 10.5 8.4-8.4L12 0 0 12l12 12 2.1-2.1-8.4-8.4H24v-3z" fill="#fff" fillRule="evenodd"/>
            </svg>
          </button>
          <h1>{i18n.t("processing_title")}</h1>
        </header>
        <div className="loader-content">
          <img
            className="icon-clock"
            src={assetUrl("assets/images/icon-clock.png")}
            alt=""
          />
          {/*<LatestPacksView onGoToClick={this.props.onGoToPacksPage} />*/}
          {this.renderTimer()}
          <p className="loader-content-text-1" dangerouslySetInnerHTML={{__html: i18n.t("processing_text_1")}} />
          <p className="loader-content-text-2" dangerouslySetInnerHTML={{__html: i18n.t("processing_text_2")}} />
          <div className="loader-slider">
            <SliderView slides={this.state.slides} />
          </div>
          <button
            className="btn btn-transparent"
            onClick={this.handleGoToAnotherStaffClick}>
            {i18n.t("button_more")}
          </button>
        </div>
      </div>
    </main>;
  }
}