import React from "react";
import AppContext from "../../contexts/AppContext";
import routes from "../../routes";
import i18n from "../../i18n";
import {assetUrl, throttle} from "../../utils/etc";
import DisclaimerModal from "./DisclaimerModal";
import clientStorage from "../../utils/client-storage";
import LatestPacksView from "../shared/LatestPacksView";
import {generatePath} from "react-router";
import SliderView from "./SliderView";
import LimitReachedModal from "./LimitReachedModal";
import LimitReachedGoTooncoinModal from "./LimitReachedGoTooncoinModal";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAppIds, webviewOpenBrowser, webviewShowBanner} from "../../utils/webview";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import MessageModal from "../shared/MessageModal";

const tooncoinAppStoreUrl = {
  android: "https://play.google.com/store/apps/details?id=com.toonlabs.tooncoin",
  ios: "https://apps.apple.com/app/id1635754347",
}

export default class IndexPage extends React.Component {

  componentDidMount() {
    if (clientStorage.getShouldRedirectToCreate()) {
      clientStorage.setShouldRedirectToCreate(false);
      this.props.history.push(routes.CREATE);
    }

    this.webviewTabSelected$ = window.webviewEventsListeners.tabSelected.subscribe((v) => {
      if (v > 0) {
        this.logIndexVisit();
      }
    }, true);

    this.context.hideLoader();
  }

  componentWillUnmount() {
    this.webviewTabSelected$ && this.webviewTabSelected$.unsubscribe();
  }

  logIndexVisit = throttle(2000, () => {
    hitEvent(hits.indexVisit);
    logEvent(userEvents.INDEX_VISIT);
  });

  showLimitReachedModal = () => {
    this.context.pushModal(<LimitReachedModal
      hideCloseButton={true}
      key="IndexPage_LimitReachedModal" />);
  }

  showLimitReachedGoTooncoinModal = () => {
    this.context.pushModal(<LimitReachedGoTooncoinModal
      key="IndexPage_LimitReachedGoTooncoinModal"
      onGetAppClick={() => {
        if (window.clientConfig.isAnyIOS) {
          webviewOpenBrowser(tooncoinAppStoreUrl.ios);
        } else {
          webviewOpenBrowser(tooncoinAppStoreUrl.android);
        }
      }}
    />);
  }

  showDisclaimerModal = () => {
    this.context.pushModal(<DisclaimerModal
      key="IndexPage_DisclaimerModal"
      onConfirm={this.handleGoToCreatePage}
      hideCloseButton={true}
    />);
  }

  showNotAvailableInUserCountryModal = () => {
    this.context.pushModal(<MessageModal
      key="IndexPage_NotAvailableInUserCountryModal"
      messageText={i18n.t("not_available_country")}
      buttonText={"OK"}
    />);
  };

  handleStartClick = () => {
    hitEvent(hits.indexGoPress);
    logEvent(userEvents.INDEX_GO_PRESS);

    // if (!window.clientConfig.isBenefitCountry && !window.clientConfig.isPro) {
    //   if (isWebviewApp(webviewAppIds.tooncoin)) {
    //     this.showNotAvailableInUserCountryModal();
    //   } else {
    //     webviewShowBanner("avatars_indo");
    //   }
    //
    //   return;
    // }
    //
    // if (this.context.activeIds.isEmpty()) {
    //   // if (isWebviewApp(webviewAppIds.tooncoin)) {
    //     this.showLimitReachedModal();
    //   // } else {
    //   //   this.showLimitReachedGoTooncoinModal();
    //   // }
    //
    //   return;
    // }
    //
    // if (parseInt(window.clientConfig.webviewParams["session_idx"] || 1) <= 1) {
    //   this.showDisclaimerModal();
    //   return;
    // }

    this.handleGoToCreatePage();
  };

  handleGoToCreatePage = () => {
    this.props.history.push(routes.CREATE);
  };

  handleGoToPacksPage = () => {
    this.props.history.push(routes.PACKS);
    // this.props.history.replace(generatePath(routes.PACK, {
    //   id: this.context.packs.first().id,
    // }));
  }

  render() {
    return <section className="main-page">
      <SliderView />

      <div className="main-page-block">
        <h1>
          <span dangerouslySetInnerHTML={{__html: i18n.t("landing_page__title")}} />
          <img
            src={assetUrl("assets/images/icon-text.png")}
            alt=""
            hidden={this.context.activeIds.isEmpty()}
          />
        </h1>

        {/* <p className="main-page-subtitle" hidden={isProcessed} dangerouslySetInnerHTML={{__html: i18n.t("landing_page__text_1")}} /> */}
        <p
          className="main-page-text"
          hidden={this.context.activeIds.isEmpty()}
          dangerouslySetInnerHTML={{__html: i18n.t("landing_page__text_2")}}
        />

        <p
          className="main-page-text"
          hidden={this.context.activeIds.isNotEmpty()}
          dangerouslySetInnerHTML={{__html: i18n.t("landing_page__notice_only_one_pack")}}
        />

        <button
          className="btn btn-violet"
          onClick={this.handleStartClick}>
          <img src={assetUrl("assets/images/icon-rocket.png")} alt="" />
          {i18n.t("button__go")}
        </button>
      </div>

      <LatestPacksView
        hidden={this.context.packs.isEmpty()}
        onGoToClick={this.handleGoToPacksPage} />
    </section>;
  }
}

IndexPage.contextType = AppContext;
