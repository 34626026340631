import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";

export default class DisclaimerModal extends Modal {

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();

    super.dismiss(trigger);
  }

  handleConfirmButtonClick = () => {
    this.dismiss();
    this.props.onConfirm();
  }

  renderModal() {
    return <React.Fragment>
      <h1 dangerouslySetInnerHTML={{__html: i18n.t("disclaimer_modal__title")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("disclaimer_modal__text")}} />
      <button
        className="btn btn-white"
        onClick={this.handleConfirmButtonClick}>
        {i18n.t("disclaimer_modal__button")}
      </button>
    </React.Fragment>;
  }
}
