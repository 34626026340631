import React from "react";

export default class ResultImageView extends React.Component {

  state = {
    isLoaded: false,
  };

  render() {
    const {
      imageUrl,
      onDownload,
      onSelect,
    } = this.props;

    const classNames = ["image-item"];
    !this.state.isLoaded && classNames.push("loading");

    return <div className={classNames.join(" ")}>
      <div className="holder">
        <button className="btn-download" onClick={() => onDownload(imageUrl)}>
          <svg viewBox="0 0 20 20" fill="none">
            <path d="M15.834 9.583 10.001 15 4.167 9.583m5.834 5.415V2.5M2.5 18.334h15" stroke="#000" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <img
          style={{opacity: this.state.isLoaded ? 1 : 0}}
          onLoad={() => {this.setState({isLoaded: true})}}
          src={imageUrl}
          onClick={() => onSelect(imageUrl)}
          alt="" />
      </div>
    </div>;
  }
}