module.exports = {
    "landing_page__title": "AI Avatars",
    "landing_page__text_1": "What are your avatars like? Time to meet them",
    "landing_page__text_2": "Let our AI blow your imagination with a great number of incredible neural avatars generated right from your photos.",
    "landing_page__notice_only_one_pack": "Only one pack is allowed. Please, wait a few days for more options. Also you can ask your friend to install our app and process your photos.",

    "disclaimer_modal__title": "Disclaimer",
    "disclaimer_modal__text": "Please note that our AI is as almighty as it is unpredictable. This means that the generated results however stunning may contain artefacts, inaccuracies, and scenes one may consider improper/provocative/vivid.",
    "disclaimer_modal__button": "Continue",

    "create_page__title": "Upload your photos",
    "create_page__rule_1": "<span>6 Photos</span>",
    "create_page__rule_2": "All the photos are of <span>the same person</span>",
    "create_page__rule_3": "<span>Only one person</span> in a photo",
    "create_page__rule_4": "<span>Selfies</span> and <span>portraits</span>",
    "create_page__rule_5": "Different <span>backgrounds</span>",
    "create_page__rule_6": "Different <span>face expressions</span>",
    "create_page__rule_7": "Photos are taken from <span>different angles</span>",
    "create_page__rule_8": "No photoshoots (settings should differ)",
    "create_page__rule_9": "No group portraits",
    "create_page__rule_10": "No nudes",
    "create_page__rule_11": "No kids",
    "create_page__photos_count": "Uploaded: <span>{{num}} photos</span>",
    "create_page__photos_error": "(Some photos cannot be used. Tap to learn more)",
    "create_page__delete_photo": "Select a photo to delete",

    "pack_page__title": "Results",

    "packs_page__title": "My avatars",

    "button__go": "go!",
    "button__ok": "ok",
    "button__select_photos": "select photos",
    "button__select_more": "select more",
    "button__add_more": "Add more",
    "button__proceed": "proceed",
    "button__select_to_delete": "Select a photo to delete",
    "button__tap_to_delete": "Tap a photo to delete",
    "button__cancel": "cancel",
    "button__delete": "delete",
    "button__download_all": "Download all",
    "button__download_avatar": "download avatar",
    "button_more": "Go to feed for more",

    "processing_title": "Processing",
    "processing_text_1": "The AI is now using your photos for training. <span>It is gonna take some time</span>, so please keep an eye on the countdown above.",
    "processing_text_2": "Here are some <span>cool effects</span> to play with from other sections of our app to <span>brighten</span> your waiting.",
    "processing_expired_text": "We need some more time",

    "latest_packs_title": "Your previous avatars",

    "limit_reached_title": "Limit reached",
    // "limit_reached_message": "For now we provide only one avatar pack per user. More packs are going to be available to ToON holders in a few days. Stay TOONED!",
    "limit_reached_message": "For now we provide only one avatar pack per user. Please, wait a few days for more options. Also you can ask your friend to install our app and process your photos.",
    "limit_reached_button": "OK",

    "limit_reached_go_tooncoin_free_title": "We currently provide only one avatar pack per user.",
    "limit_reached_go_tooncoin_free_message": "More options are coming. Need another go right now? Feel free to get the <span class='text-yellow'>ToonCoin</span> app.",
    "limit_reached_go_tooncoin_pro_message_1": "We appreciate you being our premium subscriber, however we need several more days to make additional avatar packs available to you. While we are on it, please remember that all other premium options are still there.",
    "limit_reached_go_tooncoin_pro_message_2": "If you feel like getting one more pack right now, get our ToonCoin app.",
    "limit_reached_go_tooncoin_button": "get the app",

    "not_available_country": "AI avatars are not available in your country yet.",

    "watermark_screen__title": "",
    "draggable_watermark_tooltip": "<span>Tap&Drag</span> to move <br>& delete watermark",
    "watermark_removal_modal__title": "Yep, you can remove the watermark, but…",
    "watermark_removal_modal__text1": "We’d like you to keep it and let your friends know about our app. Don’t feel like keeping it? Spread the hash-word about {{app_name}}.",
    "watermark_removal_modal__text2": "The entire amount of money raised goes to a charity fund!",
    "watermark_removal_modal__notice": "<span className=\"text-bold\">TIP:</span> you can just drag the watermark to the position you want.",
    "watermark_removal_modal__choice": "Your choice?",
    "watermark_removal_modal__confirm": "Remove watermark",
    "watermark_removal_modal__cancel": "Spread the good!",
    "watermark_removal_modal__close": "Close",

    "pack_more_images_free_title": "Wanna see <span>«{{num}}»</span> more avatars?",
    "pack_more_images_free_text": "Go <span>PRO</span> now and enjoy all the premium privileges.",
    "pack_more_images_free_button": "GO PRO",
    "pack_more_images_trial_title": "We’ve got <span>«{{num}}»</span> more avatars for you!",
    "pack_more_images_trial_text": "You can see them right now by finishing your trial and going <span>PRO</span>.",
    "pack_more_images_trial_button": "GO PRO",
    "pack_more_images_pro": "<span>Here are your «{{num}}» more avatars</span><br> thanks to your <span>premium</span> subscription.",

    "error__default_message": "Something went wrong. Try again.",
    "error__network_message": "Please check your network connection.",
    "error__overload": "We are terrible sorry, but our servers experience too much of a load currently due to skyrocketing app downloads. Please, come back a little later. We are working hard to fix everything ASAP.",

    "create_page__duplicate_photos": "It looks like you’ve selected the same photo several times. Please choose <span>unique photos only</span>. AI needs at least <span>{{n}} unique photos</span> to start creating avatars, but with <span>{{m}} photos</span> the results can be even <span>better</span>.",

    "month_short_0": "Jan",
    "month_short_1": "Feb",
    "month_short_2": "Mar",
    "month_short_3": "Apr",
    "month_short_4": "May",
    "month_short_5": "Jun",
    "month_short_6": "Jul",
    "month_short_7": "Aug",
    "month_short_8": "Sep",
    "month_short_9": "Oct",
    "month_short_10": "Nov",
    "month_short_11": "Dec",

    // ------

    "try_another_photo": "Change photo",
    "get_the_app_button": "Get the app",

    "store_app": "app store",
    "store_google": "google play",

    "error__error": "Error",
    "internal_error": "An error occurred...",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",
};

