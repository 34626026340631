import React from "react";

export default class Loading extends React.Component {

  render() {
    return <main className="loader loader-fixed" hidden={this.props.isHidden}>
      <div className="pending-loader-container">
        <div className="pending-loader">
          <div className="pending-loader-slider" />
        </div>
      </div>
    </main>;
  }
}