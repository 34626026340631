import React from "react";

export default function ImageView({imageUrl}) {
  const className = "image-item";

  return <div className={className}>
    <div className="holder">
      <img src={imageUrl} alt="" />
    </div>
  </div>;
}