import React from "react";
import Slider from "react-slick";

const settings = {
  dots: true,
  arrows: false,
  className: "swiper",
  infinite: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 1000,
  centerMode: true,
  centerPadding: "220px",
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "280px"
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "360px"
      }
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "380px",
        dots: false,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "340px",
        dots: false,
      }
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "240px",
        dots: false,
      }
    },
    {
      breakpoint: 730,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "240px",
        dots: false,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "200px",
        dots: false,
      }
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "160px",
        dots: false,
      }
    },
    {
      breakpoint: 460,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "100px",
        dots: false,
      }
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "115px",
        dots: false,
      }
    }
  ]
};

export default class SliderView extends React.Component {

  render() {
    return <Slider {...settings}>
      {this.props.slides.map((slide) =>
        <div key={slide.img}>
          <img
            className=""
            src={slide.img}
            alt=""/>
        </div>
      )}
    </Slider>;
  }
}