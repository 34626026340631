import {mockPack} from "../data/mockPack";

let userCredentials = {};
let userClientData = {};

const packStatus = {
  pending: "pending",
  ready: "ready",
};

function getFullPath(path) {
  const url = new URL(window.appConfig.paths.neuroApi + "/" + path);

  for (let key in userClientData) {
    url.searchParams.append(key, userClientData[key]);
  }

  return url.toString();
}

function defaultHandler(res) {
  if (res.ok) {
    return res.json();
  } else {
    const error = new Error("NeuroApiResponseError");
    error.name = "NeuroApiResponseError";
    error.response = res.clone();

    throw error;
  }
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}

function createPack(images, purchase) {
  return fetch(getFullPath("packs"), {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...userCredentials,
      images,
      purchase_id: purchase.id,
      purchase_type: purchase.type,
    }),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler)
}

function fetchPacks() {
  if (userClientData.is_mock) {
    return Promise.resolve({
      packs: [mockPack],
      active_ids: [],
    });
  }

  return fetch(getFullPath(""), {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({...userCredentials}),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler)
}

function fetchPack(packId) {
  if (packId === mockPack.id) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockPack), 3000);
    });
  }

  return fetch(getFullPath(`packs/${packId}`), {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler)
}

function setUserCredentials(creds) {
  userCredentials = creds;
  userCredentials.platform = window.clientConfig.isWebviewIOS ? "ios" : "android";
}

function setUserClientData(data) {
  userClientData = data;
}

export default {
  setUserCredentials,
  setUserClientData: setUserClientData,
  createPack: createPack,
  fetchPacks,
  fetchPack,
  packStatus,
};