import React from 'react';
import AppContext from "../../contexts/AppContext";
import {fileToJson, webviewCall} from "../../utils/webview";
import {photolabTask} from "../../photolab/api";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {normalizeError} from "../../photolab/handlers/helpers";
import clientStorage from "../../utils/client-storage";
import uploadHandler from "../../utils/upload.handler";
import neuroApiHelper from "../../helpers/neuro-api.helper";
import {generatePath} from "react-router";
import routes from "../../routes";
import i18n from "../../i18n";
import {assetUrl, runOnceByLocalStorage, runOnceBySessionStorage} from "../../utils/etc";
import ImageErrorModal from "./ImageErrorModal";
import ImageView from "./ImageView";
import {imageStatus} from "./shared";
import * as webviewUtils from "../../utils/webview";
import {getAmountPhotos} from "../../utils/config.utils";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import MessageModal from "../shared/MessageModal";

const minImagesThreshold = 6;
const maxImagesThreshold = 20;

export default class CreatePage extends React.Component {

  state = {
    isEditMode: false,
    images: [],
  };

  fileFieldRef = React.createRef();

  componentDidMount() {
    logEvent(userEvents.CREATE_VISIT);

    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFileSelected);
    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.replace(routes.INDEX);
      return true;
    });

    this.context.hideLoader();
    this.checkPendingImages();
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
    window.webviewEventsListeners.backPress.pop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.images.length > 0) {
      runOnceByLocalStorage("hit_userSelectPhoto", () => {
        hitEvent(hits.userSelectPhoto);
      });
    }

    this.checkPendingImages();
    this.hitAmountSelectedImages();
  }

  checkPendingImages = () => {
    this.state.images
      .filter((image) => image.status === imageStatus.pending)
      .forEach((image) => this.checkImage(image));
  };

  hitAmountSelectedImages = () => {
    const hitsIds = [
      9320, 9321, 9322, 9323, 9324,
      9325, 9326, 9327, 9328, 9329,
    ];

    const validImagesAmount = Math.min(
      this.state.images.count((image) => image.status === imageStatus.valid),
      10
    );

    for (let i = 0; i < validImagesAmount; i++) {
      runOnceBySessionStorage("CreatePage_SelectedImage_" + i, () => {
        hitEvent(hitsIds[i]);
      });
    }
  }

  requestPhotoChooser = () => {
    webviewCall("nativePhotoSelect", {
      func: "onNativeAppPhotoSelected",
      use_crop: 0,
      // num_photos: getAmountPhotos(),
      num_photos: 10,
      show: "gallery",
      tab: "faces",
      neurocamera: 1,
    });
  };

  checkImage = (image) => {
    const taskConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(image.url))
      .addMethod(new PhotolabTaskCollageMethod(4021));

    photolabTask(taskConfig.build())
      .then((res) => {
        image.resultUrl = res.resultUrl.replace("http://", "https://");
        image.status = imageStatus.valid;

        this.setState({image: this.state.images.slice()});
      })
      .catch((err) => {
        image.error = normalizeError(err);
        image.status = imageStatus.invalid;

        this.setState({image: this.state.images.slice()});
      });
  };

  handleWebviewFileSelected = (data) => {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    const stateImages = this.state.images.slice();
    let hasDuplicates = false;

    data.photos
      .map((image) => image.image_url.replace("http://", "https://"))
      .map((image) => fileToJson(image))
      .forEach((image) => {
        if (stateImages.findIndex((_) => _.hash === image.hash) === -1) {
          image.status = imageStatus.pending;
          stateImages.push(image);
        } else {
          hasDuplicates = true;
        }
      });

    this.setState({
      images: stateImages.filter((i) => i.status !== imageStatus.invalid)
    });

    if (hasDuplicates) {
      // runOnceBySessionStorage("CreatePage_DuplicatesModal", () => {
        this.context.pushModal(<MessageModal
          key="CreatePage_DuplicatesModal"
          messageText={i18n.t("create_page__duplicate_photos", {n: minImagesThreshold, m: 10})}
          buttonText={"OK"}
        />);
      // });
    }
  };

  handleBrowserFileSelected(images) {
    this.context.showLoader();

    const stateImages = this.state.images.slice();
    const hashes = stateImages.map((i) => i.hash);

    Promise.all([...images].map((image) => uploadHandler(image, hashes).catch(() => {/* ignore */})))
      .then((images) => {
        images
          .filter(Boolean)
          .forEach((image) => {
            image.status = imageStatus.pending;
            stateImages.push(image);
          });

        this.setState({
          images: stateImages.filter((i) => i.status !== imageStatus.invalid)
        }, this.context.hideLoader);
      })
      .catch(console.error);
  }

  handleRemoveImages = () => {
    const images = this.state.images.filter((i) => !i.checked);

    this.setState({
      isEditMode: false,
      images,
    });
  };

  handleRemoveImage = (image) => {
    const pos = this.state.images.findIndex((_) => _.hash === image.hash);

    if (pos > -1) {
      this.state.images.splice(pos, 1);
      this.setState({
        images: this.state.images.slice(),
      });
    }
  };

  handleImageClick = (image) => {
    if (image.status === imageStatus.invalid) {
      this.context.pushModal(<ImageErrorModal
        key="CreatePage_ImageErrorModal"
        image={image}
        hideCloseButton={true}
        onOkClick={() => this.handleRemoveImage(image)}
      />);
      return;
    }

    image.checked = !image.checked;

    if (Boolean(window.navigator.vibrate)) {
      window.navigator.vibrate(200);
    }

    this.setState({
      isEditMode: this.state.images.count(_ => _.checked) > 0,
      images: this.state.images.slice(),
    });
  };

  handleImageLongClick = (image) => {
    this.handleImageClick(image);
  };

  handleProcessClick = () => {
    const images = this.state.images
      .filter((image) => image.status === imageStatus.valid)
      .map((image) => image.url)
      .slice(0, maxImagesThreshold);

    if (images.length < minImagesThreshold) {
      return;
    }

    // const purchase = this.context.activeIds.first();
    // if (purchase === null) {
    //   return;
    // }

    const purchase = {
      id: "yfugivycu356",
      type: "special",
    };

    logEvent(userEvents.PACK_CREATE_REQUEST, {
      images_count: images.length,
    });

    this.context.showLoader(() => {
      neuroApiHelper.createPack(images, purchase).then((pack) => {
        hitEvent(hits.packCreated);
        logEvent(userEvents.PACK_CREATED, {
          pack_id: pack.id,
          images_count: images.length,
        });

        if (window.clientConfig.isWebview) {
          webviewUtils.webviewCheckPhotoPack(pack.id);
        }

        this.setState({images: []});

        neuroApiHelper.fetchPacks().then((res) => {
          this.context.setPacks(res.packs);
          this.context.setActiveIds(res.active_ids);
          this.props.history.replace(generatePath(routes.PACK, {id: pack.id}));
        }).catch(() => {
          this.props.history.replace(generatePath(routes.PACK, {id: pack.id}));
        });
      }).catch((err) => {
        console.error(err);

        this.context.pushModal(<MessageModal
          key="CreatePage_ErrorModal"
          headingText={i18n.t("error__error")}
          messageText={i18n.t(err.name === "NetworkError" ? "error__network_message" : "error__default_message")}
          buttonText={"OK"}
        />);
        this.context.hideLoader();
      });
    });
  };

  handleAddPhotosClick = () => {
    if (window.clientConfig.isWeb) {
      if (this.fileFieldRef) {
        this.fileFieldRef.value = "";
        this.fileFieldRef.click();
      }
    } else {
      this.requestPhotoChooser();
    }
  };

  handleGoToIndexPage = () => {
    this.props.history.push(routes.INDEX);
  };

  handleStartEditMode = () => {
    this.state.images.forEach((image) => image.checked = false);
    this.setState({isEditMode: true});
  };

  handleCancelEditMode = () => {
    this.state.images.forEach((image) => image.checked = false);
    this.setState({isEditMode: false});
  };

  render() {
    const validImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.valid;
    });

    const invalidImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.invalid;
    });

    const selectButtonText = this.state.images.length > 0
      ? i18n.t("button__select_more")
      : i18n.t("button__select_photos");

    const sectionSelectClass = this.state.images.length > 0 ? "select-photo" : "upload-photo";

    return <React.Fragment>
      <div className={`container ${sectionSelectClass}`}>
        <header>
          <button onClick={this.handleGoToIndexPage}>
            <svg viewBox="0 0 24 24"><path d="m5.7 10.5 8.4-8.4L12 0 0 12l12 12 2.1-2.1-8.4-8.4H24v-3z" fill="#fff" fillRule="evenodd"/></svg>
          </button>
          <h1>{i18n.t("create_page__title")}</h1>
        </header>

        <div className="images-grid" hidden={this.state.images.length === 0}>
          <div className="images-grid-header">
            <p
              className="btn-upload"
              dangerouslySetInnerHTML={{__html: i18n.t("create_page__photos_count", {num: validImagesCount})}}
            />
            <p
              className="error-text"
              dangerouslySetInnerHTML={{__html: i18n.t("create_page__photos_error")}}
              hidden={invalidImagesCount === 0}
            />

            <button
              className="btn-select-delete"
              hidden={this.state.isEditMode}>
              {i18n.t("button__tap_to_delete")}
            </button>

            <div className="btns-option-container" hidden={!this.state.isEditMode}>
              <button
                className="btn-option"
                onClick={this.handleCancelEditMode}>
                {i18n.t("button__cancel")}
              </button>

              <button
                className="btn-option"
                onClick={this.handleRemoveImages}>
                <img src={assetUrl("assets/images/icon-trash.png")} alt="" />
                {i18n.t("button__delete")}
              </button>
            </div>
          </div>

          <div className="images-grid-list">
            <button
              key="button_add_more_photos"
              className="btn-more"
              onClick={this.handleAddPhotosClick}>
              <div className="btn-more-content">
                <img src={assetUrl("assets/images/icon-camera-black.png")} alt="" />
                {i18n.t("button__add_more")}
              </div>
            </button>

            {this.state.images.map((image) => <ImageView
              key={image.url}
              image={image}
              onClick={this.handleImageClick}
              onLongClick={this.handleImageLongClick}
              isSelectable={this.state.isEditMode}
            />)}
          </div>
        </div>

        <ul className="list-conditions">
          <li>
            <img src={assetUrl("assets/images/icon-double-exclamation.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_1")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-women.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_2")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-cross.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_3")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-camera.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_4")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-sunrise.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_5")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-squinting-face.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_6")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-upside-down-face.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_7")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-no-entry.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_8")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-no-entry.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_9")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-no-entry.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_10")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-no-entry.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_11")}} />
          </li>
        </ul>

        <div className="btn-container">
          <button
            className="btn btn-shadow btn-violet"
            onClick={this.handleAddPhotosClick}
            hidden={validImagesCount >= minImagesThreshold}>
            {selectButtonText}
          </button>

          <button
            className="btn btn-violet btn-shadow"
            onClick={this.handleProcessClick}
            hidden={validImagesCount < minImagesThreshold}>
            {i18n.t("button__proceed")}
          </button>

          <input
            className="file-field-hidden"
            type="file"
            accept="image/*"
            multiple
            ref={(ref) => this.fileFieldRef = ref}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => this.handleBrowserFileSelected(e.target.files)} />
        </div>
      </div>
    </React.Fragment>;
  }
}

CreatePage.contextType = AppContext;