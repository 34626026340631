export const mockPack = {
  "id": "test",
  "subscription_id": "",
  "status": "ready",
  "images": [
    "https://temp-images.ws.pho.to/9ed8d8ff89d061cdce51b3c86c396a3623078b2a.jpeg",
    "https://temp-images.ws.pho.to/cead36d4cc52b19b6b9e1d376430812fb8d99149.jpeg",
    "https://temp-images.ws.pho.to/231d2163a1dc43cd12062bc4acc9261f41aa9e03.jpeg",
    "https://temp-images.ws.pho.to/aae8d59ed60fefa47e5ff39366523349b4b6000d.jpeg",
    "https://temp-images.ws.pho.to/cf2dc4dfbb75d3bd8ef8e952cc7131f0b0832a9a.jpeg",
    "https://temp-images.ws.pho.to/fc9535c72bf7d06beade52da1295d4090d821086.jpeg",
    "https://temp-images.ws.pho.to/30e9d68aba00a7eaa4fc479faa465d88a75d76ab.jpeg",
    "https://temp-images.ws.pho.to/d5dbfdaadbce3ad29e8c8a5c63040e82eef77796.jpeg",
    "https://temp-images.ws.pho.to/762809ca4475c862872348309ca7241d008bd74b.jpeg",
    "https://temp-images.ws.pho.to/2dbe1fed8993eeb5230d9560985d024f90f5d05b.jpeg",
    "https://temp-images.ws.pho.to/a73ae48e5394f4aeb66bdb177022cbda7797f4c6.jpeg"
  ],
  "results": [
    "https://storage.ws.pho.to/r1/e85f1e171285df9d9ac577595961aa257c707c21.jpg",
    "https://storage.ws.pho.to/r1/7f0af12c3992c264573a10ed917e3be8dac5b752.jpg",
    "https://storage.ws.pho.to/r1/f06cf25fc87dc4fb4c7ce4c4f47a56d525bc25de.jpg",
    "https://storage.ws.pho.to/r1/bfaae4252f413dc1a5a1eca4b4f7b462e1e08e01.jpg",
    "https://storage.ws.pho.to/r1/e3198d336525f2a0974922159f72ba022377424c.jpg",
    "https://storage.ws.pho.to/r1/f734a556d391bbf3accdbd3052648f9dabf2b75f.jpg",
    "https://storage.ws.pho.to/r1/cbfdb3936e20d3460c334a16ce4460413bdcea25.jpg",
    "https://storage.ws.pho.to/r1/f4cbd8b04ae8ebba9ba37600c32aa8221bcc23c2.jpg",
    "https://storage.ws.pho.to/r1/1bdbba71df1734896f2b66cf42327ed2864e5219.jpg",
    "https://storage.ws.pho.to/r1/d7d8e4d1911eb873e8719398ab05b5a9b69b4fa7.jpg",
    "https://storage.ws.pho.to/r1/aee6a6e54bd87a0d7d24a97ae232eb116104aac5.jpg",
    "https://storage.ws.pho.to/r1/621d35cde8802d7fcb5e1aa046578bb5d375f65a.jpg",
    "https://storage.ws.pho.to/r1/d7fdd0325bb52d1a8339f57cfc1389bf9b44a74b.jpg",
    "https://storage.ws.pho.to/r1/13abb5f68e2f98217b01cd0e6a60213953b4782e.jpg",
    "https://storage.ws.pho.to/r1/3c69ce8ddc28e1d77ba7485d175cc9810c428687.jpg",
    "https://storage.ws.pho.to/r1/07940eeb20713eba9998a142370bf6a7717594d1.jpg",
    "https://storage.ws.pho.to/r1/57f6cec7a377b024f0c91b91830b22bb20b07e21.jpg",
    "https://storage.ws.pho.to/r1/05c8d61a9b939317a49eeec881da8bee4a29632b.jpg",
    "https://storage.ws.pho.to/r1/9efea9a15565e9830d92d01c5a37d42accb3786b.jpg",
    "https://storage.ws.pho.to/r1/d35023b3bfb2dd612156707ae030ceaa631abbe5.jpg",
    "https://storage.ws.pho.to/r1/c480d3e6526b89132961d64a0a25c6cb8b1a20e2.jpg",
    "https://storage.ws.pho.to/r1/30b4222b7ee7ff2bfa66af8b39cbc4b4dd73f84f.jpg",
    "https://storage.ws.pho.to/r1/5a00b58b4748453211ca9762963afa56e04856ad.jpg",
    "https://storage.ws.pho.to/r1/1dab402ca48a7cd9045c1adc4ac5c44a6fa91601.jpg",
    "https://storage.ws.pho.to/r1/3fbb49035e1ea270e235fbb9bf6a1eb6b1df9756.jpg",
    "https://storage.ws.pho.to/r1/cdb072ce04a6716ac88808d4c3dc9fd9b42415e9.jpg",
    "https://storage.ws.pho.to/r1/a3915ee64e0c956d39588df27bb89d6edcaa3d5b.jpg",
    "https://storage.ws.pho.to/r1/b439315226c1b20e172b5ed848f2587e67c1e444.jpg",
    "https://storage.ws.pho.to/r1/7470c58c05b83a606c20cf0bf98208d62ad81c38.jpg",
    "https://storage.ws.pho.to/r1/bebebe422a3b5123d3204664e44025ff9fcde843.jpg",
    "https://storage.ws.pho.to/r1/4e666066276aee31e3c4524bbfdc04cfc73154ce.jpg",
    "https://storage.ws.pho.to/r1/c501c11948a72e7d2f8357a69d27e0262f7bae88.jpg",
    "https://storage.ws.pho.to/r1/20589d568be7796762239807b874233d380d41d8.jpg",
    "https://storage.ws.pho.to/r1/fd37403cdf11c7d2dde1f271b267f945d2cfb637.jpg",
    "https://storage.ws.pho.to/r1/d3c57361b9b707dca931e0d607e79257f4382191.jpg",
    "https://storage.ws.pho.to/r1/d891aac4a8e4a2f367fcc23328a2edbdf42db99b.jpg",
    "https://storage.ws.pho.to/r1/eeb256e1a53ab850d4277341574f596c699d9e05.jpg",
    "https://storage.ws.pho.to/r1/78db001271a68dcbc50298679d2d7c6c75d551b6.jpg",
    "https://storage.ws.pho.to/r1/89c79338d2d8d7160566b5b767c411ed99043330.jpg",
    "https://storage.ws.pho.to/r1/d436b54be57bfa18f412668e04a34514b4aca620.jpg",
    "https://storage.ws.pho.to/r1/713ee69efd94490cca0c15f647167846e6e5f2d6.jpg",
    "https://storage.ws.pho.to/r1/e2653270d664211c07c9c1548b86bf4d6c40fc02.jpg",
    "https://storage.ws.pho.to/r1/1d9899faca6df9352de85209a795885aec9553c1.jpg",
    "https://storage.ws.pho.to/r1/79e3b4f2c533d877d80d07dcae6c24c1849767b5.jpg",
    "https://storage.ws.pho.to/r1/2259d8fb1199723a241f2b4569e60fd98e737556.jpg"
  ],
  "duration": 1259.287178165,
  "total_duration": 1262.118309381,
  "progress": {"started": true},
  "created_at": "2022-12-12T15:34:14.819234Z"
};