import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";
import { assetUrl } from "../../utils/etc";

export default class LimitReachedGoTooncoinModal extends Modal {

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  renderModal() {
    return <React.Fragment>
      <img className="icon-money" src={assetUrl("assets/images/icon-money.png")} alt="" />

      <p hidden={!window.clientConfig.isPro} dangerouslySetInnerHTML={{__html: i18n.t("limit_reached_go_tooncoin_pro_message_1")}} />
      {/*<p hidden={!window.clientConfig.isPro} dangerouslySetInnerHTML={{__html: i18n.t("limit_reached_go_tooncoin_pro_message_2")}} />*/}

      <h1 hidden={window.clientConfig.isPro} className="text-center" dangerouslySetInnerHTML={{__html: i18n.t("limit_reached_go_tooncoin_free_title")}} />
      <p hidden={window.clientConfig.isPro} dangerouslySetInnerHTML={{__html: i18n.t("limit_reached_go_tooncoin_free_message")}} />

      {/*<button*/}
      {/*  className="btn btn-white"*/}
      {/*  onClick={this.props.onGetAppClick}>*/}
      {/*  {i18n.t("limit_reached_go_tooncoin_button")}*/}
      {/*  <img className="image-bottom" src={assetUrl("assets/images/icon-toon-logo.png")} alt="" />*/}
      {/*</button>*/}

      <button
        className="btn btn-white"
        onClick={() => this.dismiss()}>
        {i18n.t("GOT IT")}
      </button>

    </React.Fragment>;
  }
}
