import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";

export default class LimitReachedModal extends Modal {

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  renderModal() {
    return <React.Fragment>
      <h1 dangerouslySetInnerHTML={{__html: i18n.t("limit_reached_title")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("limit_reached_message")}} />
      <button
        className="btn btn-white"
        onClick={() => this.dismiss()}>
        {i18n.t("limit_reached_button")}
      </button>
    </React.Fragment>;
  }
}
